<template>
    <v-container style="margin-bottom:120px;">
        <v-row no-gutters justify="center">
            <v-col md="8">
                <h6 style="text-align:center;">ONEWORLD AYURVEDA <br>
                    <strong>(IN UBUD LOCATION)</strong>
                </h6>
                <h1 style="text-align:center;">Panchakarma Reservation Form</h1>
                <v-img lazy-src="https://picsum.photos/id/11/10/6"
                    src="../../assets/two-panchakarma-locations-in-bali.jpeg"></v-img>
                <h6 style="text-align:center;"><b>Two Locations, One Mission – Living in Balance </b></h6>
                <h4 style="text-align:center;">Your chosen destination is our <br>
                    <strong>Ubud Panchakarma Center</strong>
                </h4>
                <div class="border-information">
                    <ul>
                        <li>50% deposit is payable via VISA, Mastercard, or bank transfer and must be made within 7
                            working days from booking confirmation.</li>
                        <li>Oneworld Ayurveda does not offer refunds on your deposit, however up to 100% of your deposit
                            may be used for another retreat within 12 months of the original Panchakarma date.
                            Cancellation penalties may apply.</li>
                        <li>Oneworld Ayurveda does not offer credit for arriving late or leaving early and no partial
                            refunds for any services not used during your Panchakarma.</li>
                        <li>Payment indicates agreement to:
                            <br>
                            <a target="_blank"
                                href="https://www.oneworldayurveda.com/program-participants-disclaimer/">Oneworld
                                Ayurveda Participants Disclaimer</a>
                            <br>
                            <a target="_blank" href="https://www.oneworldayurveda.com/privacy-policy/">Oneworld Ayurveda
                                Privacy Policy</a>

                        </li>
                    </ul>
                </div>
                <p class="para">We recommend you book your flights <b>ONLY AFTER</b> you have received a confirmation
                    from us.</p>
            </v-col>
        </v-row>
        <v-form v-model="valid" ref="form" lazy-validation>
            <v-row no-gutters justify="center">
                <v-col md="8" cols="12">
                    <v-row>
                        <v-col cols="12">
                            <h6>YOUR PANCHAKARMA AT ONEWORLD AYURVEDA UBUD</h6>
                        </v-col>
                        <v-col cols="12">
                            Room type <span style="color:#ec0000;">*</span>
                            <v-select :items="rooms" v-model="chooseRoom" item-text="name" item-value="id" outlined
                                :rules="textRules"></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            I want to book a Panchakarma for <span style="color:#ec0000;">*</span>
                            <v-select :items="chooseBook" v-model="chooseDate" item-text="book" item-value="id" outlined
                                :rules="textRules"></v-select>
                        </v-col>
                        <v-col cols="12">
                            Panchakarma dates <span style="color:#ec0000;">*</span>
                            <v-select :items="chooseDates" v-model="chooseDetail" item-text="details" item-value="id"
                                outlined :rules="textRules"></v-select>
                        </v-col>
                        <v-col cols="12">
                            <p>Panchakarma Price</p>
                            <h2>{{ formatRupiah(payload.price) }}</h2>
                            <!-- <v-text-field v-model="payload.price" outlined :rules="price" readonly></v-text-field> -->
                        </v-col>
                        <v-col cols="12" v-show="menstrual">
                            <p><b>Please note</b>—the first four days of the <b>menstrual cycle</b> can interfere with
                                detox treatments, please consider this when selecting your dates if this applies to you.
                            </p>
                        </v-col>
                    </v-row>
                    <!-- guest 1 -->
                    <v-row>
                        <v-col cols="12">
                            <h6 v-if="payload.max == 1">Your personal information</h6>
                            <h6 v-else>First participant</h6>
                        </v-col>
                        <v-col cols="12" sm="4">
                            Title <span style="color:#ec0000;">*</span>
                            <v-select :items="titles" v-model="payload.guest1.title" outlined
                                :rules="textRules"></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            First Name <span style="color:#ec0000;">*</span>
                            <v-text-field v-model="payload.guest1.first" outlined :rules="nameRules"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            Last Name <span style="color:#ec0000;">*</span>
                            <v-text-field v-model="payload.guest1.last" outlined :rules="nameRules"></v-text-field>
                        </v-col>
                        <!-- list 2 -->
                        <v-col cols="12">
                            <span><i>Important: If you're booking for a friend, please make sure you provide your
                                    friend's email address, not your own, as it will be associated with their
                                    Panchakarma information.</i></span>
                        </v-col>
                        <v-col cols="12" sm="6">
                            Country of residence <span style="color:#ec0000;">*</span>
                            <v-select :items="country" v-model="payload.guest1.country" item-text="name" outlined
                                :rules="textRules"></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            Email address <span style="color:#ec0000;">*</span>
                            <v-text-field v-model="payload.guest1.email" outlined :rules="emailRules"></v-text-field>
                        </v-col>
                        <!-- list phone -->
                        <v-col cols="12" sm="4">
                            Mobile phone number <span style="color:#ec0000;">*</span>
                            <v-text-field placeholder="+6123456789000" v-model="payload.guest1.phone" outlined
                                :rules="phoneRules"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            WhatsApp number
                            <v-text-field placeholder="+6123456789000" v-model="payload.guest1.wa" outlined
                                :disabled="payload.guest1.samewa" :rules="waRules"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-checkbox v-model="setWa1" label="I use the same number for WhatsApp"></v-checkbox>
                        </v-col>
                        <!-- list 3 -->
                        <v-col cols="12" sm="6">
                            Have you done a Panchakarma before? <span style="color:#ec0000;">*</span>
                            <v-radio-group row v-model="payload.guest1.pkbefore">
                                <v-radio v-for="(method, index) in pkbefore" :key="index" :label="method.id"
                                    :value="method.id" :rules="textRules"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="6" v-show="payload.guest1.pkbefore == 'Yes'">
                            If yes, where?
                            <v-select :items="wheres" v-model="payload.guest1.pklocation" outlined></v-select>
                        </v-col>
                        <v-col cols="12">
                            Do you have any serious health issues or recent surgeries? <span
                                style="color:#ec0000;">*</span>
                            <v-radio-group row v-model="payload.guest1.healthissue">
                                <v-radio v-for="(method, index) in pkbefore" :key="index" :label="method.id"
                                    :value="method.id" :rules="textRules"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            IMPORTANT: If you have any existing health issues or have had recent surgeries, please let
                            us know as our Ayurvedic Physicians will need to assess whether you can safely do a
                            Panchakarma (Ayurvedic detox) before your arrival.
                            <v-textarea outlined v-model="payload.guest1.health"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            Special request
                            <v-textarea outlined v-model="payload.guest1.special"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            How did you hear about us <span style="color:#ec0000;">*</span>
                            <v-select class="text-hidden" v-model="payload.How_did_you_hear_about_us" :items="hears"
                                :rules="textRules" outlined></v-select>
                        </v-col>
                        <v-col cols="12"
                            v-show="payload.How_did_you_hear_about_us == 'Friend' || payload.How_did_you_hear_about_us == 'Other'">
                            Please tell us your friend's name and email so both of you can benefit from an IDR 1,000,000
                            credit towards your Panchakarma
                        </v-col>
                        <v-col cols="6"
                            v-show="payload.How_did_you_hear_about_us == 'Friend' || payload.How_did_you_hear_about_us == 'Other'"
                            class="text-hidden">
                            Friend's name or your other source of information <span style="color:#ec0000;">*</span>
                            <v-text-field v-model="payload.Source_of_info"
                                :rules="payload.How_did_you_hear_about_us == 'Friend' || payload.How_did_you_hear_about_us == 'Other' ? textRules : ''"
                                outlined></v-text-field>
                            <!-- <v-text-field v-model="payload.Source_of_info" outlined></v-text-field> -->
                        </v-col>
                        <v-col cols="6"
                            v-show="payload.How_did_you_hear_about_us == 'Friend' || payload.How_did_you_hear_about_us == 'Other'"
                            class="text-hidden">
                            Friend's email address
                            <!-- <v-text-field v-model="payload.Friend_s_email" :rules="emailformatRules" outlined></v-text-field> -->
                            <v-text-field v-model="payload.Friend_s_email" outlined></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- guest 2 -->
                    <v-row v-if="payload.max == 2">
                        <v-col cols="12">
                            <hr style="margin-bottom:40px;">
                            <h6>Second participant</h6>
                        </v-col>
                        <v-col cols="12" sm="4">
                            Title <span style="color:#ec0000;">*</span>
                            <v-select :items="titles" v-model="payload.guest2.title" outlined
                                :rules="textRules"></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            First Name <span style="color:#ec0000;">*</span>
                            <v-text-field v-model="payload.guest2.first" outlined :rules="nameRules"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            Last Name <span style="color:#ec0000;">*</span>
                            <v-text-field v-model="payload.guest2.last" outlined :rules="nameRules"></v-text-field>
                        </v-col>
                        <!-- list 2 -->
                        <v-col cols="12">
                            <spam><i>Each guest must provide a unique, valid email address (as we will contact guests
                                    separately to gather more
                                    information to tailor the Panchakarma). </i></spam>
                        </v-col>
                        <v-col cols="12" sm="6">
                            Country of residence <span style="color:#ec0000;">*</span>
                            <v-select :items="country" v-model="payload.guest2.country" item-text="name" outlined
                                :rules="textRules"></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            Email address <span style="color:#ec0000;">*</span>
                            <v-text-field v-model="payload.guest2.email" outlined :rules="emailRules2"></v-text-field>
                        </v-col>
                        <!-- list phone -->
                        <v-col cols="12" sm="4">
                            Mobile phone number <span style="color:#ec0000;">*</span>
                            <v-text-field placeholder="+6123456789000" v-model="payload.guest2.phone" outlined
                                :rules="phoneRules"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            WhatsApp number
                            <v-text-field placeholder="+6123456789000" v-model="payload.guest2.wa" outlined
                                :disabled="payload.guest2.samewa" :rules="waRules"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-checkbox v-model="setWa2" label="I use the same number for WhatsApp"></v-checkbox>
                        </v-col>
                        <!-- list 3 -->
                        <v-col cols="12" sm="6">
                            Have you done a Panchakarma before? <span style="color:#ec0000;">*</span>
                            <v-radio-group row v-model="payload.guest2.pkbefore">
                                <v-radio v-for="(method, index) in pkbefore" :key="index" :label="method.id"
                                    :value="method.id" :rules="textRules"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="6" v-show="payload.guest2.pkbefore == 'Yes'">
                            If yes, where?
                            <v-select :items="wheres" v-model="payload.guest2.pklocation" outlined></v-select>
                        </v-col>
                        <v-col cols="12">
                            Do you have any serious health issues or recent surgeries? <span
                                style="color:#ec0000;">*</span>
                            <v-radio-group row v-model="payload.guest2.healthissue">
                                <v-radio v-for="(method, index) in pkbefore" :key="index" :label="method.id"
                                    :value="method.id" :rules="textRules"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            IMPORTANT: If you have any existing health issues or have had recent surgeries, please let
                            us know as our Ayurvedic Physicians will need to assess whether you can safely do a
                            Panchakarma (Ayurvedic detox) before your arrival.
                            <v-textarea outlined v-model="payload.guest2.health"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            Special request
                            <v-textarea outlined v-model="payload.guest2.special"></v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
                <PCPaymentShow :paymentData="payload" :valid="valid" :coba="$refs.form" />
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import axios from 'axios';
import PCPaymentShow from './PCPaymentShow.vue'

function checkSingle(val) {
    return val.type == 'Single'
}

function checkDouble(val) {
    return val.type == 'Double'
}

export default {
    name: 'BookingForm',
    data() {
        return {
            books: [],
            wheres: ["Oneworld Ayurveda Tegalalang", "Oneworld Ayurveda Ubud", "Other"],
            chooseBook: [],
            chooseDates: [],
            menu: false,
            valid: true,
            payload: {
                price: 0,
                pipeline: "Panchakarma",
                max: 1,
                arrival: '',
                end: '',
                checkout: '',
                room: '',
                duration: '',
                How_did_you_hear_about_us: '',
                Source_of_info: '',
                Friend_s_email: '',
                payment: ["else"],
                guest1: {
                    "title": "",
                    "first": "",
                    "last": "",
                    "country": "",
                    "phone": "",
                    "wa": "",
                    "samewa": false,
                    "email": "",
                    "pkbefore": "",
                    "healthissue": "",
                    "pklocation": "",
                    "special": "",
                    "health": ""
                },
                guest2: {
                    "title": "",
                    "first": "",
                    "last": "",
                    "country": "",
                    "phone": "",
                    "wa": "",
                    "samewa": false,
                    "email": "",
                    "pkbefore": "",
                    "healthissue": "",
                    "pklocation": "",
                    "special": "",
                    "health": ""
                }
            },
            menstrual: false,
            // pkbefore: ["Yes", "No"],
            pkbefore: [
                {
                    'id': 'Yes',
                },
                {
                    'id': 'No',
                }
            ],
            rooms: [
                {
                    id: 1,
                    type: 'Single',
                    name: 'Single occupancy'
                },
                {
                    id: 2,
                    type: 'Double',
                    name: 'Double occupancy (1 bed)'
                },
                {
                    id: 3,
                    type: 'Double',
                    name: 'Twin share (2 beds)'
                }
            ],
            nowDate: new Date().toISOString().slice(0, 10),
            country: [
                { name: 'Afghanistan', code: 'AF' },
                { name: 'Åland Islands', code: 'AX' },
                { name: 'Albania', code: 'AL' },
                { name: 'Algeria', code: 'DZ' },
                { name: 'American Samoa', code: 'AS' },
                { name: 'AndorrA', code: 'AD' },
                { name: 'Angola', code: 'AO' },
                { name: 'Anguilla', code: 'AI' },
                { name: 'Antarctica', code: 'AQ' },
                { name: 'Antigua and Barbuda', code: 'AG' },
                { name: 'Argentina', code: 'AR' },
                { name: 'Armenia', code: 'AM' },
                { name: 'Aruba', code: 'AW' },
                { name: 'Australia', code: 'AU' },
                { name: 'Austria', code: 'AT' },
                { name: 'Azerbaijan', code: 'AZ' },
                { name: 'Bahamas', code: 'BS' },
                { name: 'Bahrain', code: 'BH' },
                { name: 'Bangladesh', code: 'BD' },
                { name: 'Barbados', code: 'BB' },
                { name: 'Belarus', code: 'BY' },
                { name: 'Belgium', code: 'BE' },
                { name: 'Belize', code: 'BZ' },
                { name: 'Benin', code: 'BJ' },
                { name: 'Bermuda', code: 'BM' },
                { name: 'Bhutan', code: 'BT' },
                { name: 'Bolivia', code: 'BO' },
                { name: 'Bosnia and Herzegovina', code: 'BA' },
                { name: 'Botswana', code: 'BW' },
                { name: 'Bouvet Island', code: 'BV' },
                { name: 'Brazil', code: 'BR' },
                { name: 'British Indian Ocean Territory', code: 'IO' },
                { name: 'Brunei Darussalam', code: 'BN' },
                { name: 'Bulgaria', code: 'BG' },
                { name: 'Burkina Faso', code: 'BF' },
                { name: 'Burundi', code: 'BI' },
                { name: 'Cambodia', code: 'KH' },
                { name: 'Cameroon', code: 'CM' },
                { name: 'Canada', code: 'CA' },
                { name: 'Cape Verde', code: 'CV' },
                { name: 'Cayman Islands', code: 'KY' },
                { name: 'Central African Republic', code: 'CF' },
                { name: 'Chad', code: 'TD' },
                { name: 'Chile', code: 'CL' },
                { name: 'China', code: 'CN' },
                { name: 'Christmas Island', code: 'CX' },
                { name: 'Cocos (Keeling) Islands', code: 'CC' },
                { name: 'Colombia', code: 'CO' },
                { name: 'Comoros', code: 'KM' },
                { name: 'Congo', code: 'CG' },
                { name: 'Congo, The Democratic Republic of the', code: 'CD' },
                { name: 'Cook Islands', code: 'CK' },
                { name: 'Costa Rica', code: 'CR' },
                { name: 'Cote D\'Ivoire', code: 'CI' },
                { name: 'Croatia', code: 'HR' },
                { name: 'Cuba', code: 'CU' },
                { name: 'Cyprus', code: 'CY' },
                { name: 'Czech Republic', code: 'CZ' },
                { name: 'Denmark', code: 'DK' },
                { name: 'Djibouti', code: 'DJ' },
                { name: 'Dominica', code: 'DM' },
                { name: 'Dominican Republic', code: 'DO' },
                { name: 'Ecuador', code: 'EC' },
                { name: 'Egypt', code: 'EG' },
                { name: 'El Salvador', code: 'SV' },
                { name: 'Equatorial Guinea', code: 'GQ' },
                { name: 'Eritrea', code: 'ER' },
                { name: 'Estonia', code: 'EE' },
                { name: 'Ethiopia', code: 'ET' },
                { name: 'Falkland Islands (Malvinas)', code: 'FK' },
                { name: 'Faroe Islands', code: 'FO' },
                { name: 'Fiji', code: 'FJ' },
                { name: 'Finland', code: 'FI' },
                { name: 'France', code: 'FR' },
                { name: 'French Guiana', code: 'GF' },
                { name: 'French Polynesia', code: 'PF' },
                { name: 'French Southern Territories', code: 'TF' },
                { name: 'Gabon', code: 'GA' },
                { name: 'Gambia', code: 'GM' },
                { name: 'Georgia', code: 'GE' },
                { name: 'Germany', code: 'DE' },
                { name: 'Ghana', code: 'GH' },
                { name: 'Gibraltar', code: 'GI' },
                { name: 'Greece', code: 'GR' },
                { name: 'Greenland', code: 'GL' },
                { name: 'Grenada', code: 'GD' },
                { name: 'Guadeloupe', code: 'GP' },
                { name: 'Guam', code: 'GU' },
                { name: 'Guatemala', code: 'GT' },
                { name: 'Guernsey', code: 'GG' },
                { name: 'Guinea', code: 'GN' },
                { name: 'Guinea-Bissau', code: 'GW' },
                { name: 'Guyana', code: 'GY' },
                { name: 'Haiti', code: 'HT' },
                { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
                { name: 'Holy See (Vatican City State)', code: 'VA' },
                { name: 'Honduras', code: 'HN' },
                { name: 'Hong Kong', code: 'HK' },
                { name: 'Hungary', code: 'HU' },
                { name: 'Iceland', code: 'IS' },
                { name: 'India', code: 'IN' },
                { name: 'Indonesia', code: 'ID' },
                { name: 'Iran, Islamic Republic Of', code: 'IR' },
                { name: 'Iraq', code: 'IQ' },
                { name: 'Ireland', code: 'IE' },
                { name: 'Isle of Man', code: 'IM' },
                { name: 'Israel', code: 'IL' },
                { name: 'Italy', code: 'IT' },
                { name: 'Jamaica', code: 'JM' },
                { name: 'Japan', code: 'JP' },
                { name: 'Jersey', code: 'JE' },
                { name: 'Jordan', code: 'JO' },
                { name: 'Kazakhstan', code: 'KZ' },
                { name: 'Kenya', code: 'KE' },
                { name: 'Kiribati', code: 'KI' },
                { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
                { name: 'Korea, Republic of', code: 'KR' },
                { name: 'Kuwait', code: 'KW' },
                { name: 'Kyrgyzstan', code: 'KG' },
                { name: 'Lao People\'S Democratic Republic', code: 'LA' },
                { name: 'Latvia', code: 'LV' },
                { name: 'Lebanon', code: 'LB' },
                { name: 'Lesotho', code: 'LS' },
                { name: 'Liberia', code: 'LR' },
                { name: 'Libyan Arab Jamahiriya', code: 'LY' },
                { name: 'Liechtenstein', code: 'LI' },
                { name: 'Lithuania', code: 'LT' },
                { name: 'Luxembourg', code: 'LU' },
                { name: 'Macao', code: 'MO' },
                { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
                { name: 'Madagascar', code: 'MG' },
                { name: 'Malawi', code: 'MW' },
                { name: 'Malaysia', code: 'MY' },
                { name: 'Maldives', code: 'MV' },
                { name: 'Mali', code: 'ML' },
                { name: 'Malta', code: 'MT' },
                { name: 'Marshall Islands', code: 'MH' },
                { name: 'Martinique', code: 'MQ' },
                { name: 'Mauritania', code: 'MR' },
                { name: 'Mauritius', code: 'MU' },
                { name: 'Mayotte', code: 'YT' },
                { name: 'Mexico', code: 'MX' },
                { name: 'Micronesia, Federated States of', code: 'FM' },
                { name: 'Moldova, Republic of', code: 'MD' },
                { name: 'Monaco', code: 'MC' },
                { name: 'Mongolia', code: 'MN' },
                { name: 'Montserrat', code: 'MS' },
                { name: 'Morocco', code: 'MA' },
                { name: 'Mozambique', code: 'MZ' },
                { name: 'Myanmar', code: 'MM' },
                { name: 'Namibia', code: 'NA' },
                { name: 'Nauru', code: 'NR' },
                { name: 'Nepal', code: 'NP' },
                { name: 'Netherlands', code: 'NL' },
                { name: 'Netherlands Antilles', code: 'AN' },
                { name: 'New Caledonia', code: 'NC' },
                { name: 'New Zealand', code: 'NZ' },
                { name: 'Nicaragua', code: 'NI' },
                { name: 'Niger', code: 'NE' },
                { name: 'Nigeria', code: 'NG' },
                { name: 'Niue', code: 'NU' },
                { name: 'Norfolk Island', code: 'NF' },
                { name: 'Northern Mariana Islands', code: 'MP' },
                { name: 'Norway', code: 'NO' },
                { name: 'Oman', code: 'OM' },
                { name: 'Pakistan', code: 'PK' },
                { name: 'Palau', code: 'PW' },
                { name: 'Palestinian Territory, Occupied', code: 'PS' },
                { name: 'Panama', code: 'PA' },
                { name: 'Papua New Guinea', code: 'PG' },
                { name: 'Paraguay', code: 'PY' },
                { name: 'Peru', code: 'PE' },
                { name: 'Philippines', code: 'PH' },
                { name: 'Pitcairn', code: 'PN' },
                { name: 'Poland', code: 'PL' },
                { name: 'Portugal', code: 'PT' },
                { name: 'Puerto Rico', code: 'PR' },
                { name: 'Qatar', code: 'QA' },
                { name: 'Reunion', code: 'RE' },
                { name: 'Romania', code: 'RO' },
                { name: 'Russian Federation', code: 'RU' },
                { name: 'RWANDA', code: 'RW' },
                { name: 'Saint Helena', code: 'SH' },
                { name: 'Saint Kitts and Nevis', code: 'KN' },
                { name: 'Saint Lucia', code: 'LC' },
                { name: 'Saint Pierre and Miquelon', code: 'PM' },
                { name: 'Saint Vincent and the Grenadines', code: 'VC' },
                { name: 'Samoa', code: 'WS' },
                { name: 'San Marino', code: 'SM' },
                { name: 'Sao Tome and Principe', code: 'ST' },
                { name: 'Saudi Arabia', code: 'SA' },
                { name: 'Senegal', code: 'SN' },
                { name: 'Serbia and Montenegro', code: 'CS' },
                { name: 'Seychelles', code: 'SC' },
                { name: 'Sierra Leone', code: 'SL' },
                { name: 'Singapore', code: 'SG' },
                { name: 'Slovakia', code: 'SK' },
                { name: 'Slovenia', code: 'SI' },
                { name: 'Solomon Islands', code: 'SB' },
                { name: 'Somalia', code: 'SO' },
                { name: 'South Africa', code: 'ZA' },
                { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
                { name: 'Spain', code: 'ES' },
                { name: 'Sri Lanka', code: 'LK' },
                { name: 'Sudan', code: 'SD' },
                { name: 'Suriname', code: 'SR' },
                { name: 'Svalbard and Jan Mayen', code: 'SJ' },
                { name: 'Swaziland', code: 'SZ' },
                { name: 'Sweden', code: 'SE' },
                { name: 'Switzerland', code: 'CH' },
                { name: 'Syrian Arab Republic', code: 'SY' },
                { name: 'Taiwan, Province of China', code: 'TW' },
                { name: 'Tajikistan', code: 'TJ' },
                { name: 'Tanzania, United Republic of', code: 'TZ' },
                { name: 'Thailand', code: 'TH' },
                { name: 'Timor-Leste', code: 'TL' },
                { name: 'Togo', code: 'TG' },
                { name: 'Tokelau', code: 'TK' },
                { name: 'Tonga', code: 'TO' },
                { name: 'Trinidad and Tobago', code: 'TT' },
                { name: 'Tunisia', code: 'TN' },
                { name: 'Turkey', code: 'TR' },
                { name: 'Turkmenistan', code: 'TM' },
                { name: 'Turks and Caicos Islands', code: 'TC' },
                { name: 'Tuvalu', code: 'TV' },
                { name: 'Uganda', code: 'UG' },
                { name: 'Ukraine', code: 'UA' },
                { name: 'United Arab Emirates', code: 'AE' },
                { name: 'United Kingdom', code: 'GB' },
                { name: 'United States', code: 'US' },
                { name: 'United States Minor Outlying Islands', code: 'UM' },
                { name: 'Uruguay', code: 'UY' },
                { name: 'Uzbekistan', code: 'UZ' },
                { name: 'Vanuatu', code: 'VU' },
                { name: 'Venezuela', code: 'VE' },
                { name: 'Viet Nam', code: 'VN' },
                { name: 'Virgin Islands, British', code: 'VG' },
                { name: 'Virgin Islands, U.S.', code: 'VI' },
                { name: 'Wallis and Futuna', code: 'WF' },
                { name: 'Western Sahara', code: 'EH' },
                { name: 'Yemen', code: 'YE' },
                { name: 'Zambia', code: 'ZM' },
                { name: 'Zimbabwe', code: 'ZW' }
            ],
            hears: [
                'I have been a guest before',
                'Google',
                'Instagram',
                'Facebook',
                'Youtube',
                'Friend',
                'Email/newsletter',
                'Other'
            ],
            durations: [],
            titles: [
                "Mr.",
                "Mrs.",
                "Ms."
            ],
            textRules: [
                v => !!v || 'This field is required',
            ],
            nameRules: [
                v => !!v || 'This field is required',
                v => /^[a-zA-Z -]+$/.test(v) || 'Name must use alphabet',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            emailformatRules: [
                v => /.+@.+\..+/.test(v) || '' || 'E-mail must be valid',
            ],
            emailRules2: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                v => this.payload.guest1.email != v || "Email address may not be the same as the first guest's email."
            ],
            phoneRules: [
                v => !!v || 'This field is required',
                v => /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{2,}$/.test(v) || 'Phone number must be at least 8 digits, using only numbers and "+".',
            ],
            waRules: [
                v => /^((\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{2,}|)$/.test(v) || 'Whatsapp number must be at least 8 digits, using only numbers and "+".',
            ],
        };
    },
    methods: {
        formatRupiah(angka) {
            // Menghapus dua desimal terakhir
            const roundedValue = Math.floor(angka);
            // Format angka dengan pemisah ribuan
            const formattedValue = new Intl.NumberFormat('id-ID').format(roundedValue);
            // Mengembalikan format dengan "Rp" dan mengganti titik dengan koma
            return `Rp ${formattedValue.replace(/\./g, ',')}`;
        },
        personMax() {
            if (this.payload.room != 'Single occupancy') {
                this.payload.max = 2
            } else {
                this.payload.max = 1
            }
        },
        paymentProcess(payload) {
            this.$store.dispatch('setPayload', payload)
            this.$router.push({ path: '/confirmation' })
        }
    },
    computed: {
        picker: {
            get() {
                return this.value;
            },
            set(val) {
                this.menu = false;
                this.payload.arrival = val
            }
        },
        chooseRoom: {
            get() {
                return this.value
            },
            set(val) {
                var data = this.rooms.find(r => r.id == val)
                this.payload.room = data.name
                if (this.payload.price > 0) {
                    this.payload.price = 0
                    this.payload.arrival = ''
                    this.payload.duration = ''
                    this.payload.end = ''
                }
                if (data.id == '1') {
                    this.chooseBook = this.books.filter(checkSingle)
                    this.chooseDates = []
                    this.payload.max = 1
                } else {
                    this.chooseBook = this.books.filter(checkDouble)
                    this.chooseDates = []
                    this.payload.max = 2
                }
            }
        },
        chooseDate: {
            get() {
                return this.value
            },
            set(val) {
                var data = this.books.find(r => r.id == val)
                this.payload.duration = data.book
                this.chooseDates = []
                this.payload.price = 0
                this.payload.end = ''
                this.payload.arrival = ''
                if (parseInt(data.book.slice(0, -6)) == 7) {
                    this.menstrual = true
                } else {
                    this.menstrual = false
                }
                // axios.post('http://localhost:3344/panchakarma/price-list-standart-owr', {"panchakarma_book": data.id}).then(response => {
                axios.post('https://api.oneworldbali.com/panchakarma/price-list-standart-owr', { "panchakarma_book": data.id }).then(response => {
                    // this.chooseDates = response.data
                    for (let i = 0; i < response.data.length; i++) {
                        let d = new Date(response.data[i].start).toISOString().slice(0, 10);
                        if (this.nowDate < d) {
                            this.chooseDates.push(response.data[i])
                        }
                    }
                }).catch(error => {
                    console.log(error);
                })
            }
        },
        chooseDetail: {
            get() {
                return this.value
            },
            set(val) {
                var data = this.chooseDates.find(r => r.id == val)
                let d = new Date(data.start).toISOString().slice(0, 10);
                let e = new Date(data.end).toISOString().slice(0, 10);
                this.payload.arrival = d
                this.payload.end = e


                this.payload.price = data.price;
                if (this.payload.room === 'Double occupancy (1 bed)' || this.payload.room === 'Twin share (2 beds)') {
                    this.payload.price *= 2; // Kalikan harga dengan 2 jika kamar double
                }
            }
        },
        setWa1: {
            get() {
                return this.value
            },
            set(val) {
                if (val == true) {
                    this.payload.guest1.wa = this.payload.guest1.phone
                }
                this.payload.guest1.samewa = val
            }
        },
        setWa2: {
            get() {
                return this.value
            },
            set(val) {
                if (val == true) {
                    this.payload.guest2.wa = this.payload.guest2.phone
                }
                this.payload.guest2.samewa = val
            }
        }
    },
    mounted() {
        // axios.get('http://localhost:3344/panchakarma/book-list-owr').then(response => {
        axios.get('https://api.oneworldbali.com/panchakarma/book-list-owr').then(response => {
            this.books = response.data
        }).catch(error => {
            console.log(error);
        })
    },
    components: {
        PCPaymentShow
    },
    metaInfo: {
        title: 'Panchakarma at',
        titleTemplate: '%s Oneworld Ayurveda Ubud'
    }
};
</script>